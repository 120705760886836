<template>
  <div class="background-outer">
    <main>
      <Header :showButtons="false" />
      <div class="background-inner">
        <p>Proudly made by Franziska Haaf and Maria Mazur</p>
        <h2>Imprint</h2>
        <section class="content-section">
          <p>Team Internet AG</p>
          <p>Liebherrstr. 22</p>
          <p>80538 München</p>
          <p>s2simulator@tonic.com<br /></p>
        </section>
        <section class="content-section">
          <p>
            <span class="helpertext">Board of Management:</span>
            Gaëlle Lallement & Axel Kaltz
          </p>
          <p>
            <span class="helpertext">Chairman of the Supervisory Board:</span>
            Michael Riedl
          </p>
          <p>
            <span class="helpertext">Domicile and Court of Registry:</span>
            München / Munich
          </p>
          <p>
            <span class="helpertext">Commercial Register No.:</span>
            HRB 200081
          </p>
          <p>
            <span class="helpertext">VAT:</span>
            DE270418747
          </p>
        </section>
      </div>
    </main>
  </div>
</template>

<style scoped>
.background-inner {
  text-align: center;
  padding: 48px;
}

.content-section p {
  margin: 0;
}

.content-section + .content-section {
  border-top: 2px solid rgba(0, 0, 0, 0.12);
  margin-top: 24px;
  padding-top: 24px;
}
p {
  max-width: 100%;
}
</style>

<script>
import Header from "@/components/shared/Header";

export default {
  name: "Imprint",
  data: () => ({}),
  components: { Header },
  methods: {},
};
</script>
